<template>
  <div class="relative flex h-[52px] items-center overflow-hidden !text-[#282E38]">
    <div
      ref="marquee"
      class="animate-marquee flex whitespace-nowrap text-lg font-bold lg:text-xl"
      :style="{ animationDelay: '0s' }"
    >
      <div v-for="index in numItems" :key="index" class="flex items-center">
        <i class="uil uil-tag-alt !mt-[1px]" style="color: #818ea1 !important" /><span class="mx-4 flex items-center">
          Até 15% de desconto na 1ª compra
        </span>
        <div class="mx-[37px] h-2 w-2 rounded-full bg-[#282E38]"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

const numItems = 100;

const marquee = ref(null);

onMounted(() => {
  marquee.value.style.animationPlayState = "running";
});
</script>

<style scoped>
@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.animate-marquee {
  animation: marquee 1000s linear infinite;
  display: inline-flex;
  width: max-content;
  animation-play-state: paused;
}
</style>
