<template>
  <div v-if="notificationsList.length > 0" ref="menuWrapper" class="relative">
    <Button
      :badge="1"
      :badgeClass="badgeClass"
      outlined
      class="without-border flex h-12 !min-h-fit w-12 items-center justify-center focus:!shadow-none lg:min-h-[50px]"
      type="button"
      rounded
      icon="uil uil-bell text-neutral-low-dark"
      @click="toggleMenu"
    />
    <div
      v-if="showMenu"
      class="absolute left-1/2 top-1/2 mt-8 w-[294px] -translate-x-1/2 transform rounded-md border bg-white shadow-lg lg:left-auto lg:right-0 lg:mt-2 lg:-translate-x-0 lg:transform-none z-50"
    >
      <div
        class="flex h-[52px] items-center justify-between bg-neutral-high-ultra px-7 py-4 font-semibold text-neutral-low-dark"
      >
        <span>Notificações</span>

        <div v-if="summary.unread >= 1" class="flex items-center justify-center">
          <span
            class="inline-flex h-[21px] w-[21px] items-center justify-center rounded-full text-[10.5px] font-bold leading-[21px] text-white"
            style="background-color: #ff4f00"
          >
            {{ summary.unread }}
          </span>
        </div>
      </div>

      <div class="max-h-[364px] overflow-y-auto">
        <div v-for="(notification, index) in notificationsList" :key="index" class="flex border-b hover:bg-gray-50">
          <Notification :notification="notification" />
        </div>
      </div>

      <div
        class="cursor-pointer px-7 py-4 text-center text-lg font-semibold hover:bg-gray-50"
        @click="handleRedirectToNotifications"
      >
        <a :href="routeNotifications.url">ver todas</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { storeToRefs } from "pinia";
import { onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from "vue";

import { routeNotifications } from "@/routes/pages";
import { getNotifications } from "@/services/notifications";
import { useNotificationStore } from "@/stores/NotificationStore";

import Notification from "./components/Notification.vue";

const NotificationStore = useNotificationStore();

const { notifications } = storeToRefs(NotificationStore);

const badgeClass = ref();
const showMenu = ref(false);
const menuWrapper = ref(null);
const notificationsList = ref([]);
const summary = ref();

function handleClickOutside(event) {
  if (menuWrapper.value && !menuWrapper.value.contains(event.target)) {
    showMenu.value = false;
  }
}

function toggleMenu() {
  showMenu.value = !showMenu.value;
}

const handleGetNotifications = async () => {
  try {
    await getNotifications("?has_pagination=1").then((res) => {
      const response = res.data;
      if (res.data) {
        notificationsList.value = response.notifications;
        notificationsList.value.map((item) => {
          item.updated_at = moment(item.updated_at).format("DD/MM/YYYY");
        });
        summary.value = res.data.summary;
        badge();
      }
      return;
    });
  } catch (error) {
    console.error(error);
  }
};

const badge = () => {
  badgeClass.value =
    summary.value.unread === 0
      ? "custom-badge !text-transparent opacity-0"
      : "custom-badge !text-transparent opacity-100";
};

const handleRedirectToNotifications = () => {
  window.location.href = routeNotifications.url;
};

onBeforeMount(() => {
  handleGetNotifications();
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

watch(notifications, () => {
  handleGetNotifications();
});
</script>

<style lang="scss" scoped>
@import "@/styles/themes/tailwind/tailwind-light/_variables.scss";

:deep(#overlay_notify) {
  top: 38px !important;
  right: 0 !important;
  left: auto !important;

  max-height: 300px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

:deep(.p-button) {
  position: relative;
  overflow: visible;

  .p-badge.custom-badge {
    position: absolute;
    top: -4px;
    right: -4px;
    order: 1;
    width: 10px !important;
    vertical-align: middle;
    color: #fff;
    background-color: #60c46f;
    font-weight: normal;
    @apply bg-status-positive-light;
  }

  .p-button-icon {
    position: absolute;
  }
}
:deep(.p-tieredmenu-root-list) {
  @apply pt-12;
}
.without-border {
  border: none !important;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 25px;

  background: #f2f4f6;
}

::-webkit-scrollbar-thumb {
  border-radius: 25px;

  background: #e2e5e9;
}
</style>
1
